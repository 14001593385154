interface UpsellLinksTypes {
  en?: string;
  es?: string;
  de?: string;
  fr?: string;
  it?: string;
}

export const UpsellsLinks: Record<string, UpsellLinksTypes> = {
  meal_planning: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget.pdf?alt=media&token=c1267e38-5945-4347-aa6c-312646ad4a33',
    es: '',
    de: '',
  },
  cortisol_detox: {
    en: 'https://firebasestorage.googleapis.com/v0/b/no-diet-app-prod.firebasestorage.app/o/upsells%2Fnodiet_cortisol.pdf?alt=media&token=ddd24e9f-4660-40c2-b4ad-0eddf846861f',
    es: '',
    de: '',
  },
  fasting: {
    en: 'https://firebasestorage.googleapis.com/v0/b/no-diet-app-prod.firebasestorage.app/o/upsells%2Fnodiet_fastingmadeeasy.pdf?alt=media&token=27c8aa3d-1354-4537-9112-1f89a9098e85',
    es: '',
    de: '',
  },
  mindfull_eating: {
    en: 'https://firebasestorage.googleapis.com/v0/b/no-diet-app-prod.firebasestorage.app/o/upsells%2Fnodiet_10steps.pdf?alt=media&token=d29af84d-93d0-45c5-b52e-70108c5feb26',
    es: '',
    de: '',
  },
  menopause: {
    en: 'https://firebasestorage.googleapis.com/v0/b/no-diet-app-prod.firebasestorage.app/o/upsells%2Fnodiet_menopause.pdf?alt=media&token=e6feb4be-5a90-4a3b-b9a8-0740f7cca59f',
    es: '',
    de: '',
  },
  menstrual: {
    en: 'https://firebasestorage.googleapis.com/v0/b/no-diet-app-prod.firebasestorage.app/o/upsells%2Fnodiet_menstrualcycle.pdf?alt=media&token=fb0e3991-4f79-439a-9d3e-3b5c3375f4fb',
    es: '',
    de: '',
  },
  get_fitter: {
    en: 'https://firebasestorage.googleapis.com/v0/b/no-diet-app-prod.firebasestorage.app/o/upsells%2Fnodiet_healthyhabits.pdf?alt=media&token=da67e0b4-4485-44db-ba0f-324f31f160db',
    es: '',
    de: '',
  },
};
